import {http, param} from '../utils/http'
import conf from '../config'

export const API_ROOT = `${conf.API_URL}`
const excelParser = {

    getExcelHeaders(file) {
        return http.post(`${API_ROOT}/excelParser/excel-headers`,file).then(resp => {
            return resp
        })
    },
}

export default excelParser