import product from './product'
import supplierRecallService from '../../../../services/supplier/supplier-recall'
import { Product } from '../../../../misc/commons'
import ImportProductModal from './import-product-modal'
import store from '../../../../store'

export default {
    props: ['communicationId', 'productData', 'producFieldList', 'recallDisabled','infoData','communicationName','status','communicationType'],
    components: {
        product,
        ImportProductModal
    },
    data() {
        return {
            products: [],
            updateStep0: '',
            producFieldMetaList: [],
            product_legend: '',
            productModalKey: 0,
            loading: false,
            isShow: true,
            isProcessingList: false,
            info: store.state.userType == 'SUPPLIER' ? {} : null,
            orgType: store.state.userType,
        }
    },
    mounted() {
        // if(this.orgType == 'SUPPLIER'){
        //     this.info = this.infoData
        // }
        this.info = this.infoData
        this.products = this.productData.map((x, index) => {
            return {
                ...x,
                fields: x.fields.map(y => {
                    let _val = y.value
                    if (y.type === 'Date') {
                        _val = { valueDate: y.value.valueDate }
                    }
                    return {
                        ...y,
                        value: _val
                    }
                }),
                order: index + 1
            }
        })
        this.producFieldMetaList = this.producFieldList
        if (this.orgType == 'SUPPLIER') {
            if (this.info.status != undefined && this.info.status == 'OPEN') {
                this.isShow = false
            }
        }
        if (this.orgType == 'PROVIDER') {
            if (this.info.modifiedDatetime != null) {
                this.isShow = false
            }
        }
    },
    computed: {
        nextOrder() {
            if (this.products && this.products.length > 0) {
                return Math.max(this.products.map(x => x.order)) + 1
            } else {
                return 1
            }
        }
    },
    methods: {
        showImportProductModal() {
            if (this.orgType == 'SUPPLIER') {
                this.productModalKey += 1
                this.$nextTick(() => {
                    this.$refs.importProductModal.forceRerender(this.communicationId, this.producFieldMetaList, this.info?.isCustomizedContent?? false)
                })
            }
        },

        onImportCompleted() {
            //reload product data...
            this.loading = true
            supplierRecallService.communicationDetail(this.communicationId).then(res => {
                if (!res.error) {
                    let _productData = res.data.d.products
                    this.products = _productData.map((x, index) => {
                        return {
                            ...x,
                            fields: x.fields.map(y => {
                                let _val = y.value
                                if (y.type === 'Date') {
                                    _val = { valueDate: y.value.valueDate }
                                }
                                return {
                                    ...y,
                                    value: _val
                                }
                            }),
                            order: index + 1
                        }
                    })
                    this.loading = false
                }
            })
        },

        addProduct() {
            let product = new Product({ id: null, order: this.nextOrder })
            this.products.push(product)
        },
        removeProduct(index) {
            if (this.products && this.products.length > 0) {
                if ((this.products.length ==1 && this.info.status=='OPEN' && this.orgType == 'SUPPLIER')|| (this.products.length ==1 && this.orgType == 'PROVIDER'))
                {
                    this.products.splice(index, 1)
                    return new Promise((resolve, reject) => {
                        supplierRecallService.saveCommunicationStep1Products({
                            id: this.communicationId,
                            communicationType: this.communicationType,
                            products: []
                        }).then(res => {
                            if (!res.error) {
                                resolve(res.data.d)
                            }
                        })
                    })
                }
                else {
                    this.products.splice(index, 1)
                }
            }
        },

        save(type) {
            return new Promise((resolve, reject) => {
                if (this.products && this.products.length > 0) {
                    this.$refs['observer'].validate().then(isValid => {
                        if (isValid) {
                            let _products = this.fetchProductItemData()
                            if (_products && _products.length > 0 && _products.length === this.products.length) {
                                supplierRecallService.saveCommunicationStep1Products({
                                    id: this.communicationId,
                                    communicationType: this.communicationType,
                                    products: _products,
                                    clicktype: type,
                                    updateStep0: this.updateStep0
                                }).then(res => {
                                    if (!res.error) {
                                        resolve(res.data.d)
                                    }
                                })
                            }
                        } else {
                            reject('invalid step 1')
                        }
                    })
                } else {
                    supplierRecallService.saveCommunicationStep1Products({
                        id: this.communicationId,
                        communicationType: this.communicationType,
                        products: [],
                        clicktype: type,
                        updateStep0: this.updateStep0
                    }).then(res => {
                        if (!res.error) {
                            resolve(res.data.d)
                        }
                    })
                }
            })
        },

        fetchProductItemData() {
            let _products = []
            for(let i = 0; i < this.products.length; i++) {
                let _product = this.$refs.products[i].fetchProductData()
                if (_product) {
                    _products.push(_product)
                }
            }
            return _products
        },

    }
}