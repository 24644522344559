import { RECALL_TYPEOFCHANGE,USER_TYPE, DISRUPTION_TYPEOFCHANGE } from '../../../../misc/commons'
import supplierRecallService from '../../../../services/supplier/supplier-recall'
import providerRecallService from '../../../../services/provider/recall'
import store from '../../../../store'


export default {
    props: {
        communicationType: { type: String, default: null }
    },
    data() {
        return {
            communicationId: null,
            content: '',
            typeofchange: null,
            updatenotification: false,
            recallTypeOfChange: this.communicationType == 'RECALL' ? RECALL_TYPEOFCHANGE : DISRUPTION_TYPEOFCHANGE,
            sendType: 'SEND_UPDATE_NOTI',
            orgType: store.state.userType,
            service: store.state.userType ==USER_TYPE.SUPP ? supplierRecallService : providerRecallService,
        }
    },
    methods: {
        sendNotification(eve) {
            if (eve) {
                this.updatenotification = false
            }
            else {
                this.updatenotification = true
            }
        },
        forceRerender(communicationId) {
            this.communicationId = communicationId
            this.content = ''
            this.typeofchange = null
            this.updatenotification = false
            this.$refs.modal.show()
        },
        onClose() {
            this.$refs.modal.hide()
            this.communicationId = null
            this.content = ''
        },
        onClomplete() {
            this.$refs.modal.hide()
            this.$emit('onCompleted')
            this.communicationId = null
            this.content = ''
            this.typeofchange = null
            this.updatenotification = false
        },

        save() {
            if (this.orgType == USER_TYPE.PROV) {
                if (this.content.trim() !== '') {
                    providerRecallService.saveRecallChangeHistory({ id: this.communicationId, content: this.content.trim() })
                        .then(res => {
                            if (!res.error) {
                                this.onClomplete()
                            }
                        })
                }
            }
            if (this.orgType == USER_TYPE.SUPP) {
                if (this.content.trim() !== '') {
                    supplierRecallService.saveRecallChangeHistory({ id: this.communicationId, communicationType: this.communicationType, content: this.content.trim(), typeofchange: this.typeofchange, updatenotification: this.updatenotification })
                        .then(res => {
                            if (!res.error) {
                                this.onClomplete()
                            }
                        })
                }
            }
            if (this.updatenotification == true) {
                supplierRecallService.sendNotification(this.communicationId, this.sendType, this.communicationType)
                    .then(res => {
                        if (!res.error) {
                            this._showToast('Sent successfully!')
                        }
                    })

            }
            else {
                this.onClomplete()
            }
        }
    }
}